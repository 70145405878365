import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "./material.module";

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireFunctionsModule, REGION } from "@angular/fire/functions";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PayrollComponent } from "./components/payroll/payroll.component";
import { LinkAccountPopupComponent } from "./components/payroll/link-account-popup/link-account-popup.component";
import { LinkAccountSuccessPopup } from "./components/payroll/link-account-success-popup/link-account-success-popup.component";
import { MainDashboardComponent } from "./layout/main-dashboard/main-dashboard.component";
import { ReferHelpPopupComponent } from "./components/dashboard/refer-help-popup/refer-help-popup.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { AppConstants } from "./app.constants";
import { ForgotPasswordComponent } from "./components/sign-in/forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    DashboardComponent,
    ProfileComponent,
    PayrollComponent,
    LinkAccountPopupComponent,
    LinkAccountSuccessPopup,
    MainDashboardComponent,
    ReferHelpPopupComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(AppConstants.Config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    MaterialModule,
    NgApexchartsModule,
  ],
  entryComponents: [
    ReferHelpPopupComponent,
    LinkAccountPopupComponent,
    LinkAccountSuccessPopup,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

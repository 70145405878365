import { Component, OnInit } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";

import { AuthService } from "src/app/shared/services/auth.service";

import { variables, methods } from "src/app/utils/utils";
import { AppConstants } from "../../app.constants";

// declare const require: any;
// const aes256 = require("aes256");

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  docId;
  profileData;
  isSaveDisabled: boolean = true;

  // on save click disable button till resp is received
  isSaveClicked = false;

  // general detail
  legalName = "";
  userName = "";
  phNum = "";
  email = "";
  addr = "";
  orgName = "";

  // Bank Info
  accType = ["Savings account", "Current account", "Salary account"];
  selectedAccType = "";
  financeInstituteName = "";
  bankRoutingNum = "";
  accNum = "";

  constructor(
    public authServices: AuthService,
    public router: Router,
    private snackbar: MatSnackBar,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (!JSON.parse(localStorage.getItem("affiliator_data")).on_boarding) {
      this.authServices.ngZone.run(() => this.router.navigate(["payroll"]));
    }
    this.route.params.subscribe((val) => {
      let params = val.response;

      if (params === "success")
        this.showMessage("Your bank details will be updated shortly");
      else if (params === "failure")
        this.showMessage("Unable to update bank details");
    });
    this.getProfile();
  }

  public getProfile() {
    this.authServices
      .getProfileData(
        "user_id",
        JSON.parse(localStorage.getItem("affiliate_user")).uid
      )
      .subscribe((res) => {
        if (res[0] !== undefined && res[0].payload !== undefined) {
          this.docId = res[0].payload.doc.id;
          this.profileData = res[0].payload.doc.data();

          // general detail
          this.legalName = this.profileData.legal_name
            ? this.profileData.legal_name
            : "";
          this.userName = this.profileData.name ? this.profileData.name : "";
          this.phNum = this.profileData.phone_number
            ? this.profileData.phone_number
            : "";
          this.email = this.profileData.email ? this.profileData.email : "";
          this.addr = this.profileData.address ? this.profileData.address : "";
          this.orgName = this.profileData.organization
            ? this.profileData.organization
            : "";

          // bank detail
          if (this.profileData.bank) {
            this.selectedAccType = this.profileData.bank.type
              ? this.profileData.bank.type
              : "";
            this.financeInstituteName = this.profileData.bank.name
              ? this.profileData.bank.name
              : "";
            this.accNum = this.profileData.bank.ac_number
              ? this.profileData.bank.ac_number
              : "";
            this.bankRoutingNum = this.profileData.bank.route_number
              ? this.profileData.bank.route_number
              : "";
            this.isSaveDisabled = false;
          }
        }
      });
  }

  handleOnInputChange = (value) => {
    this.phNum = methods.getUSFormat(value);
  };

  public validateProfileForm(): boolean {
    // if (this.legalName.trim() === "") {
    //   this.showMessage("Enter legal name");
    // } else
    if (this.userName.trim() === "") {
      this.showMessage("Enter legal name");
    } else if (this.phNum.trim() === "") {
      this.showMessage("Enter phone number");
    } else if (methods.isInvalidNumber(this.phNum)) {
      this.showMessage("Enter valid phone number");
    } else if (this.addr.trim() === "") {
      this.showMessage("Enter address");
    } else if (this.orgName.trim() === "") {
      this.showMessage("Enter organisation name");
    } else if (this.selectedAccType === "") {
      this.showMessage("Select your account type");
    }
    //  else if (this.financeInstituteName.trim() === "") {
    //   this.showMessage("Enter bank name");
    // }
    // else if (this.accNum.trim() === "") {
    //   this.showMessage("Enter account number");
    // }
    // else if (!Number(this.accNum)) {
    //   this.showMessage("Account number must be only numbers");
    // }
    // else if (this.bankRoutingNum.trim() === "") {
    //   this.showMessage("Enter bank routing number");
    // }
    else {
      return true;
    }
    return false;
  }

  public onSaveClick() {
    let isValid = this.validateProfileForm();
    if (!isValid) return;

    this.isSaveClicked = true;

    this.updateProfile();
    // this.encryptAccNum();
  }

  public encryptAccNum() {
    let key = this.docId;
    let plaintext = this.accNum;

    // let encryptedPlainText = aes256.encrypt(key, plaintext);
    // let decryptedPlainText = aes256.decrypt(key, encryptedPlainText);
  }

  public updateProfile() {
    let data = {
      legal_name: this.legalName,
      name: this.userName,
      phone_number: this.phNum,
      address: this.addr,
      organization: this.orgName,
      bank: {
        type: this.selectedAccType,
        name: this.financeInstituteName,
        ac_number: this.accNum,
        route_number: this.bankRoutingNum,
      },
    };

    this.authServices
      .updateAffiliatorProfile(data, this.docId)
      .then((resp) => {
        this.showMessage("Profile updated!!");
        this.isSaveClicked = false;
      })
      .catch((err) => {
        this.showMessage("Error in updating profile");
        this.isSaveClicked = false;
        console.error(err);
      });
  }

  onChangeAccountDetails = async () => {
    let accId = this.profileData.bank.acc_id;
    if(!accId) return;

    let data = {
      acc_id: accId,
      refresh_url: `${window.location.href}/failure`,
      return_url: `${window.location.href}/success`,
    };

    const resp = await fetch(`${AppConstants.STRIP.URL}createAccountLink`, {
      method: "POST",
      body: JSON.stringify(data),
    });

    let respData = await resp.json();
    if (respData.success) {
      let newTabUrl = respData.data.url;
      window.open(newTabUrl, "_self");
    } else {
      this.showMessage("Could not link account, please try again later");
    }
  };

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };
}

import { Component, OnInit } from "@angular/core";

//Auth - Service
import { AuthService } from "src/app/shared/services/auth.service";

//Material
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { methods, variables } from "src/app/utils/utils";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
})
export class ForgotPasswordComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  email = "";
  hasPasswordResetClicked: boolean = false;
  hasResetLinkSent: boolean = false;

  constructor(public authService: AuthService, private snackbar: MatSnackBar) {}

  ngOnInit() {}

  // CHECKING IF PROFILE EXISTS IN AFFILIATORS COLLECTION
  public checkProfile() {
    this.authService.getProfileData("email", this.email).subscribe(
      async (res) => {
        if (res[0] !== undefined && res[0].payload !== undefined) {
          if (await this.authService.forgotPassword(this.email)) {
            this.hasResetLinkSent = true;
          } else {
            window.alert("Invalid email or email id not registered.");
          }
          this.hasPasswordResetClicked = !this.hasPasswordResetClicked;
        } else {
          this.hasPasswordResetClicked = !this.hasPasswordResetClicked;
          this.showMessage("Entered email is not registered");
        }
      },
      (err) => {
        this.hasPasswordResetClicked = false;
        console.error(err);
      }
    );
  }

  handleOnSubmit = () => {
    if (this.hasPasswordResetClicked) return;

    if (this.email === "") {
      this.showMessage("Please enter valid email");
      return;
    } else if (methods.isInvalidEmail(this.email)) {
      this.showMessage("Please enter valid email");
      return;
    }
    this.hasPasswordResetClicked = !this.hasPasswordResetClicked;

    // CHECKING IF PROFILE EXISTS IN AFFILIATORS COLLECTION
    this.checkProfile();
  };

  //Message Handler
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 1500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };
}

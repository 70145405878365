import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-refer-help-popup",
  templateUrl: "./link-account-success-popup.component.html",
  styleUrls: ["./link-account-success-popup.component.scss"],
})
export class LinkAccountSuccessPopup implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<LinkAccountSuccessPopup>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    setTimeout(() => {
      this.data.getProfile();
      this.onCloseClick();
      this.data.showMessage("Your bank information has been added successfully")
    }, 10000);
  }

  onCloseClick() {
    this.dialogRef.close("cancel");
  }
}

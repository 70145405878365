import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-refer-help-popup",
  templateUrl: "./refer-help-popup.component.html",
  styleUrls: ["./refer-help-popup.component.scss"],
})
export class ReferHelpPopupComponent implements OnInit {
  img = {
    fb: "assets/refer_earn/fb_share_dialog.svg",
    ld: "assets/refer_earn/ld_share_dialog.svg",
  };

  constructor(
    private dialogRef: MatDialogRef<ReferHelpPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  onCloseClick() {
    this.dialogRef.close("cancel");
  }

  onShareClick() {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = this.data.valToCopy;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    this.dialogRef.close(this.data.invoker);
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { PayrollComponent } from "./components/payroll/payroll.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { ForgotPasswordComponent } from "./components/sign-in/forgot-password/forgot-password.component";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { VerifyEmailComponent } from "./components/verify-email/verify-email.component";
import { MainDashboardComponent } from "./layout/main-dashboard/main-dashboard.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { DeactivateGuard } from "./shared/guards/deactivate.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/sign-in",
    pathMatch: "full",
  },
  {
    path: "sign-in",
    component: SignInComponent,
    canActivate: [DeactivateGuard],
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [DeactivateGuard],
  },
  {
    path: "sign-up",
    component: SignUpComponent,
    canActivate: [DeactivateGuard],
  },
  {
    path: "verify-email-address",
    component: VerifyEmailComponent,
    canActivate: [DeactivateGuard],
  },
  {
    path: "payroll",
    component: PayrollComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "payroll/:response",
    component: PayrollComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "",
    component: MainDashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "profile",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "profile/:response",
        component: ProfileComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: "**",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

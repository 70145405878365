import { Injectable, NgZone } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";

import {
  AngularFirestore,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Router } from "@angular/router";
import { auth } from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    public firestore: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone
  ) {}

  // getting profile
  public getProfileData(param, key) {
    let query = this.firestore
      .collection("Affiliators")
      .ref.where(param, "==", key);
    return this.firestore
      .collection("Affiliators", (ref) => query)
      .snapshotChanges();
  }

  // getting graph data
  public getAffiliatorRecord(id) {
    return this.firestore
      .collection("AffiliatorReferrals")
      .doc(id)
      .snapshotChanges();
  }

  // update affiliators profile
  updateAffiliatorProfile(data, docId) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("Affiliators")
        .doc(docId)
        .set(data, { merge: true })
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  // .set(data, { merge: true })

  // Check if email already registered
  validateEmailRegistration = (email) => {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth
        .fetchSignInMethodsForEmail(email)
        .then((res) => {
          if (res && res.length > 0) {
            resolve(true);
          } else resolve(false);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(new auth.GoogleAuthProvider())
      .then((result) => {
        // this.router.navigate(["dashboard"]);
        const user = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName ? result.user.displayName : "",
          photoURL: result.user.photoURL ? result.user.photoURL : "",
          emailVerified: result.user.emailVerified,
        };

        return {
          success: true,
          message: "Login Successfully",
          data: user,
        };
      })
      .catch((error) => {
        // window.alert(error);
        return {
          success: false,
          message: error.message,
          data: {},
        };
      });
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {

        const user = {
          uid: result.user.uid,
          email: result.user.email,
          displayName: result.user.displayName ? result.user.displayName : "",
          photoURL: result.user.photoURL ? result.user.photoURL : "",
          emailVerified: result.user.emailVerified,
        };

        return {
          success: true,
          message: "Login Successfully",
          data: user,
        };
      })

      .catch((error) => {
        return {
          success: false,
          message: error.message,
          data: {},
        };
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.firestore.doc(
      `users/${user.uid}`
    );
    const userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName ? user.displayName : "",
      photoURL: user.photoURL ? user.photoURL : "",
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }

  // Register user before signUP
  registerAgent = (email, password) => {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        return {
          success: true,
          message: "registered successfully",
          data: {
            user_id: user.user.uid,
          },
        };
      })
      .catch((error) => {
        return {
          success: false,
          message: error.message,
          data: {},
        };
      });
  };

  // create agent
  createAgent(name, id) {
    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("")
        .add({
          companyName: name,
          ownerId: id,
        })
        .then(
          (res) => {
            resolve(res);
          },
          (err) => reject(err)
        );
    });
  }

  // create agent meta
  createAgentMeta(data, key, id) {
    if (key) {
      data["ownerId"] = id;
    }
    data["user_id"] = id;

    return new Promise<any>((resolve, reject) => {
      this.firestore
        .collection("Affiliators")
        .add(data)
        .then(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  // OLD CODE
  // // Reset Forggot password
  // ForgotPassword(passwordResetEmail) {
  //   return this.afAuth.auth
  //     .sendPasswordResetEmail(passwordResetEmail)
  //     .then(() => {
  //       window.alert("Password reset email sent, check your inbox.");
  //       this.router.navigate(["/sign-in"]);
  //     })
  //     .catch((error) => {
  //       window.alert(error);
  //     });
  // }

  // Reset Forggot password
  forgotPassword(passwordResetEmail) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth
        .sendPasswordResetEmail(passwordResetEmail)
        .then((res) => {
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail(email, key) {
    return this.afAuth.auth.currentUser
      .sendEmailVerification()
      .then(() => {
        this.router.navigate(["verify-email-address"], {
          queryParams: { email: email, flag: key },
        });
      })
      .catch((err) => console.error(err));
  }

  //Authentication
  isLoggedIn = () => {
    return !!localStorage.getItem("affiliate_user");
  };
}

import { Component, OnInit, ViewChildren } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AppConstants } from "src/app/app.constants";
import { AuthService } from "src/app/shared/services/auth.service";
import { methods, variables } from "src/app/utils/utils";
import { MatFormField } from "@angular/material/form-field";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  @ViewChildren(MatFormField) formFields;

  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  // step-1
  email = "";

  //  step-2
  name = "";
  organizationName = "";

  //  step-3
  password = "";
  confirmPassword = "";
  pHide = true;
  cPHide = true;

  // signup variables
  registeredData;

  // referral data
  referrerData;

  step = 1;
  totStep = 3;

  hasSignupClicked: boolean = false;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    public authService: AuthService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params && params.r_id) {
        this.email = params.email;
        let temp = {
          rId: params.r_id,
          rName: params.r_name,
        };
        this.referrerData = temp;
        localStorage.setItem("affiliator_referrer", JSON.stringify(temp));
      } else {
        this.referrerData = JSON.parse(
          localStorage.getItem("affiliator_referrer")
        );
      }
    });
  }

  ngOnInit() {
    // if (this.referrerData && this.referrerData.rId) this.updateReferralClicks();
  }

  // updating referral clicks
  updateReferralClicks = async () => {
    let data = {
      id: this.referrerData.rId,
    };

    // const resp = await fetch(`${AppConstants.STRIP.URL}updateReferralClicks`, {
    //   method: "POST",
    //   body: JSON.stringify(data),
    // });

    // const respData = await resp.json();
  };

  // public formValidator(): boolean {
  //   let isValid = false;

  //   if (this.name.trim() === "") this.showMessage("Enter your full name");
  //   else if (this.email.trim() === "") this.showMessage("Enter enail address");
  //   else if (!this.email.match(variables.EMAIL_REGEX))
  //     this.showMessage("Enter valid email address");
  //   else if (this.password.trim() === "") this.showMessage("Enter password");
  //   else if (this.organizationName.trim() === "")
  //     this.showMessage("Enter organization name");
  //   else isValid = true;
  //   return isValid;
  // }

  handleOnSubmit() {
    // let isValid = this.formValidator();
    // // let isValid = false;

    // if (!isValid) {
    //   this.router.navigate(["/payroll"]);
    //   return;
    // }

    this.authService
      .registerAgent(this.email, this.password)
      .then((response) => {
        this.registeredData = response;
        if (this.registeredData.success) {
          /* 
          ********** OBJECT KEYS **********
          name, email, organization,
          legal_name, phone_number, address,
          bank ( type, name, route_number,ac_number) , 
          referral (same obj as employer)

          // username is name
        */

          let data = {
            name: this.name,
            email: this.email,
            organization: this.organizationName,

            // is on boarded
            on_boarding: false,
          };

          let dataToSend;

          if (this.referrerData && this.referrerData.rId) {
            dataToSend = {
              ...data,
              referred_by: {
                id: this.referrerData.rId,
                name: this.referrerData.rName,
              },
            };
          } else dataToSend = { ...data };

          // this.authService
          //   .createAgent(
          //     dataToSend.name,
          //     this.registeredData.data.user_id
          //   )
          //   .then((res) => {
          // dataToSend["companyId"] = res.id;

          this.authService
            .createAgentMeta(dataToSend, true, this.registeredData.data.user_id)
            .then((resp) => {
              this.showMessage("You have registered successfuly.");

              // setTimeout(() => {
              //   this.router.navigate(["sign-in"]);
              // }, 500);

              this.authService.SendVerificationMail(dataToSend.email, false);
              this.hasSignupClicked = !this.hasSignupClicked;
            });
          // });
        } else {
          this.hasSignupClicked = !this.hasSignupClicked;
          this.showMessage(this.registeredData.message);
        }
      });
  }

  // step-1 start signup
  startSignUp = async () => {
    if (this.hasSignupClicked) return;
    else if (this.email.trim() === "") {
      this.showMessage("Please enter your email.");
      return;
    } else if (methods.isInvalidEmail(this.email)) {
      this.showMessage("Please enter valid email.");
      return;
    }

    try {
      this.hasSignupClicked = !this.hasSignupClicked;
      if (await this.authService.validateEmailRegistration(this.email)) {
        this.hasSignupClicked = !this.hasSignupClicked;
        this.showMessage("Email is already registered.");
        return;
      }
      this.hasSignupClicked = !this.hasSignupClicked;
      this.step = 2;
    } catch (err) {
      this.hasSignupClicked = !this.hasSignupClicked;
      console.error(err);
    }
  };

  // step-2: get empllyer and location
  validateName = () => {
    if (this.name.trim() === "") {
      this.showMessage("Please enter full name.");
      return;
    } else if (this.name.trim().length < 3) {
      this.showMessage("Name must be at least 3 characters long");
      return;
    } else if (this.organizationName.trim().length < 3) {
      this.showMessage("Organization name must be at least 3 characters long");
      return;
    } else if (this.organizationName.trim() === "") {
      this.showMessage("Please enter valid organzation name");
      return;
    }
    this.step = 3;
  };

  // step-3: vlaidate passs
  validateSignUp = () => {
    if (this.hasSignupClicked) return;
    if (this.password.trim() === "") {
      this.showMessage("Enter your password.");
      return;
    } else if (this.confirmPassword !== this.password) {
      this.showMessage("Passwords do not match.");
      return;
    }
    this.hasSignupClicked = true;
    this.handleOnSubmit();
  };

  handleNextClick = (val) => {
    switch (val) {
      case 1:
        this.startSignUp();
        break;
      case 2:
        this.validateName();
        break;
      case 3:
        this.validateSignUp();
        break;
      default:
        break;
    }
  };

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };

   // For material form fields refersh
   ngAfterViewInit(): void {
    setTimeout(() => {
      this.formFields.forEach(ff => {
        ff.updateOutlineGap(); 
      })}, 100
    );
  }
}

import { Component, OnInit, ViewChildren } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Router } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { methods } from "src/app/utils/utils";
import { MatFormField } from "@angular/material/form-field";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  @ViewChildren(MatFormField) formFields;

  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  email = "";
  password = "";
  pHide = true;

  hasLoginClicked: boolean = false;

  response;

  constructor(
    public router: Router,
    private snackbar: MatSnackBar,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  handleGoogleLogin() {
    this.authService
      .AuthLogin("")
      .then((result) => {

        if (!result.success) {
          this.showMessage(result.message);
          return;
        }

        this.response = result.data;

        // CHECKING IF PROFILE EXISTS IN AFFILIATORS COLLECTION
        this.checkProfile();
      })
      .catch((error) => {
        this.showMessage(error.message);
      });
  }

  validateSignIn(): boolean {
    if (this.email.trim() === "") {
      this.showMessage("Please enter vaild email");
      return false;
    } else if (methods.isInvalidEmail(this.email.trim())) {
      this.showMessage("Please enter valid email");
      return false;
    } else if (this.password.trim() === "") {
      this.showMessage("Please enter password");
      return false;
    }
    return true;
  }

  handleLogin() {
    if (this.hasLoginClicked) return;
    if (!this.validateSignIn()) return;

    this.hasLoginClicked = true;

    this.authService
      .SignIn(this.email, this.password)
      .then((result) => {
        if (!result.success) {
          this.hasLoginClicked = false;
          this.showMessage(result.message);
          return;
        }

        this.response = result.data;

        // CHECKING IF PROFILE EXISTS IN AFFILIATORS COLLECTION
        this.checkProfile();
      })
      .catch((error) => {
        this.hasLoginClicked = false;
        this.showMessage(error.message);
      });
  }

  // CHECKING IF PROFILE EXISTS IN AFFILIATORS COLLECTION
  public checkProfile() {
    this.authService.getProfileData("user_id", this.response.uid).subscribe(
      (res) => {
        if (res[0] !== undefined && res[0].payload !== undefined) {
          let data = res[0].payload.doc.data();

          if (!this.response.emailVerified) {
            this.authService.SendVerificationMail(this.response.email, true);
          } else {
            localStorage.setItem(
              "affiliate_user",
              JSON.stringify(this.response)
            );
            localStorage.setItem("affiliator_data", JSON.stringify(data));
            this.authService.SetUserData(this.response);

            this.hasLoginClicked = false;

            if (!data["on_boarding"]) {
              this.authService.ngZone.run(() =>
                this.router.navigate(["payroll"])
              );
            } else {
              this.authService.ngZone.run(() =>
                this.router.navigate(["dashboard"])
              );
            }
          }
        } else {
          this.hasLoginClicked = false;
          this.showMessage("Entered email is not registered");
        }
      },
      (err) => {
        this.hasLoginClicked = false;
        console.error(err);
      }
    );
  }

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 1300,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };

   // For material form fields refersh
   ngAfterViewInit(): void {
    setTimeout(() => {
      this.formFields.forEach(ff => {
        ff.updateOutlineGap(); 
      })}, 100
    );
  }
}

import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AppConstants } from "../../../app.constants";
import { Router, ActivatedRoute } from "@angular/router";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
@Component({
  selector: "app-account-link-popup",
  templateUrl: "./link-account-popup.component.html",
  styleUrls: ["./link-account-popup.component.scss"],
})

export class LinkAccountPopupComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  linkBtn = {
    disabled: false,
    text: "Link Account",
    isLoading: false,
  };
  texts = {
    label: "Complete your Onboarding",
    para: "Link your bank account details to get started with your Affiliate account",
  };
  constructor(
    private snackbar: MatSnackBar,
    private router: ActivatedRoute,
    private dialogRef: MatDialogRef<LinkAccountPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    if (this.data.params === "failure") {
      this.data.showMessage("Could not get your bank details.")
      this.texts = {
        label: "Failure",
        para: "Could not get your bank details. Don't worry, you can try again later",
      };
      this.linkBtn = {
        disabled: false,
        text: "Try again",
        isLoading: false,
      };
    }
  }

  onLinkClick = async (accId: string) => {
    let data = {
      acc_id: this.data.accId,
      refresh_url: `${window.location.href}/failure`,
      return_url: `${window.location.href}/success`,
    };

    this.linkBtn = {
      disabled: true,
      text: "Processing ...",
      isLoading: false,
    };

    // window.location.href=`http://localhost:4200/payroll/success`  // for testing

    const resp = await fetch(`${AppConstants.STRIP.URL}createAccountLink`, {
      method: "POST",
      body: JSON.stringify(data),
    });

    let respData = await resp.json();
    if (respData.success) {
      let newTabUrl = respData.data.url;
      window.open(newTabUrl, "_self");
    } else {
      this.showMessage("Could not link account, please try again later");
    }
  };

  onCloseClick() {
    this.dialogRef.close("cancel");
  }

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 1300,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };
}

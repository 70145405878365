import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/shared/services/auth.service";
import { variables } from "src/app/utils/utils";
import { AppConstants } from "src/app/app.constants";
import { LinkAccountPopupComponent } from "./link-account-popup/link-account-popup.component";
import { LinkAccountSuccessPopup } from "./link-account-success-popup/link-account-success-popup.component";
import { methods } from "../../utils/utils";

@Component({
  selector: "app-payroll",
  templateUrl: "./payroll.component.html",
  styleUrls: ["./payroll.component.scss"],
})
export class PayrollComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  isDone = false;
  isBankAdded = false;
  params = "";
  currStep = "Company Detail";
  steps = {
    step_one: {
      is_completed: false,
      name: "Company Detail",
    },
    step_two: {
      is_completed: false,
      name: "Bank Information",
    },
  };

  docId;
  profileData;

  // on save click disable button till resp is received
  isSaveDisabled = true;

  // Company details
  legalName = "";
  userName = "";
  email = "";
  phNum = "";
  orgName = "";
  addr = "";

  // Bank Info
  accType = ["Savings account", "Current account", "Salary account"];
  selectedAccType = "";
  financeInstituteName = "";
  bankRoutingNum = "";
  accNum = "";

  constructor(
    public router: Router,
    private snackbar: MatSnackBar,
    public authServices: AuthService,
    private dialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (JSON.parse(localStorage.getItem("affiliator_data")).on_boarding) {
      this.authServices.ngZone.run(() => this.router.navigate(["dashboard"]));
      return;
    }
    this.route.params.subscribe((val) => {
      this.params = val.response;
      if (this.params === "success") {
        const help = this.dialog.open(LinkAccountSuccessPopup, {
          disableClose: true,
          data: {
            getProfile: () => this.getProfile(),
            showMessage:  (message) => this.showMessage(message)
          },
        });
      }
    });
    this.getProfile();
  }

  private getProfile() {
    this.authServices
      .getProfileData(
        "user_id",
        JSON.parse(localStorage.getItem("affiliate_user")).uid
      )
      .subscribe((res) => {
        if (res[0] !== undefined && res[0].payload !== undefined) {
          this.docId = res[0].payload.doc.id;
          this.profileData = res[0].payload.doc.data();

          localStorage.setItem(
            "affiliator_data",
            JSON.stringify(this.profileData)
          );

          // general detail
          this.legalName = this.profileData.name ? this.profileData.name : "";
          // this.userName = this.profileData.name ? this.profileData.name : "";
          this.phNum = this.profileData.phone_number
            ? this.profileData.phone_number
            : "";
          this.email = this.profileData.email ? this.profileData.email : "";
          this.addr = this.profileData.address ? this.profileData.address : "";
          this.orgName = this.profileData.organization
            ? this.profileData.organization
            : "";

          // bank detail
          if (this.profileData.bank) {
            let bankObj = this.profileData.bank;
            this.selectedAccType = bankObj.type
              ? this.profileData.bank.type
              : "";
            this.financeInstituteName = bankObj.name
              ? this.profileData.bank.name
              : "";
            this.accNum = bankObj.ac_number
              ? this.profileData.bank.ac_number
              : "";
            this.bankRoutingNum = bankObj.route_number
              ? this.profileData.bank.route_number
              : "";
            this.isBankAdded = bankObj.is_bank_added;
            this.isSaveDisabled = false;
            if (this.profileData["on_boarding"]) {
              return;
            }
            if (this.params !== "success") {
              const help = this.dialog.open(LinkAccountPopupComponent, {
                disableClose: true,
                data: {
                  accId: bankObj.acc_id,
                  params: this.params,
                  getProfile: () => this.getProfile(),
                  showMessage:  (message) => this.showMessage(message)
                },
              });
            }
            
          }
        }
      });
  }

  public validateCompanyForm = () => {
    if (this.legalName.trim() === "") {
      this.showMessage("Enter your legal name");
    }
    // else if (this.userName.trim() === "") {
    //   this.showMessage("Enter user name");
    // }
    else if (this.phNum.trim() === "") {
      this.showMessage("Enter phone number");
    } else if (methods.isInvalidNumber(this.phNum)) {
      this.showMessage("Enter valid phone number");
    } else if (this.orgName.trim() === "") {
      this.showMessage("Enter organization name");
    }

    // else if (this.addr.trim() === "") {
    //   this.showMessage("Enter address");
    // }
    else {
      return true;
    }
    return false;
  };

  handleOnInputChange = (value) => {
    this.phNum = methods.getUSFormat(value);
  };

  public validateBankInfo = () => {
    if (this.selectedAccType === "") {
      this.showMessage("Select account type");
    }
    // else if (this.financeInstituteName.trim() === "") {
    //   this.showMessage("Enter bank name");
    // } else if (this.accNum.trim() === "") {
    //   this.showMessage("Enter account number");
    // }
    // else if (!Number(this.accNum)) {
    //   this.showMessage("Account number must be only numbers");
    // } else if (this.bankRoutingNum.trim() === "") {
    //   this.showMessage("Enter bank routing number");
    // }
    else {
      return true;
    }
    return false;
  };

  onSaveAndContinue = () => {
    if (!this.validateCompanyForm()) return;
    if (!this.validateBankInfo()) return;

    let data = {
      // legal_name: this.legalName,
      name: this.legalName,
      phone_number: this.phNum,
      address: this.addr,
      organization: this.orgName,
      bank: { type: this.selectedAccType },
      on_boarding: true,
    };
    this.isSaveDisabled = true;

    this.authServices
      .updateAffiliatorProfile(data, this.docId)
      .then((resp) => {
        this.showMessage("Company detail and bank information updated!!");
        this.steps.step_one.is_completed = true;
        this.currStep = this.steps.step_two.name;
        this.isSaveDisabled = false;
        setTimeout(() => {
          this.router.navigate(["/dashboard"]);
        }, 500);
      })
      .catch((err) => {
        this.showMessage("Error in updating profile");
        this.isSaveDisabled = false;
        console.error(err);
      });
  };

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 1300,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };
}

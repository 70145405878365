class Utils {
  variables = {
    // Email validation
    EMAIL_REGEX:
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

    // Phone number validation
    PHONE_REGEX: /^[0-9]{10}$/,
    US_PHONE_REGEX:
      /(?:\d{3}(?:\d{7}|\-\d{3}\-\d{4}))|(?:\(\d{3}\)(?:\-\d{3}\-)|(?: \d{3} )\d{4})$/,
  };

  methods = {
    getUSFormat: (number) => {
      const input = number.replace(/\D/g, "").substring(0, 10); // First ten digits of input only
      const areaCode = input.substring(0, 3);
      const middle = input.substring(3, 6);
      const last = input.substring(6, 10);

      if (input.length > 6) {
        number = `(${areaCode}) ${middle} - ${last}`;
      } else if (input.length > 3) {
        number = `(${areaCode}) ${middle}`;
      } else if (input.length > 0) {
        number = `(${areaCode}`;
      }
      return number;
    },

    isInvalidEmail: (email) => {
      let eRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!eRegex.test(email)) return true;
      else return false;
    },

    isInvalidNumber: (number) => {
      if (number === "") return true;
      else if (number.length < 16) return true;
      else return false;
    },
  };
}

const utils = new Utils();
export const variables = utils.variables;
export const methods = utils.methods;

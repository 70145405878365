import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Event, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-main-dashboard",
  templateUrl: "./main-dashboard.component.html",
  styleUrls: ["./main-dashboard.component.scss"],
})
export class MainDashboardComponent implements OnInit {
  selectedTab = "dashboard";

  profilePic = "assets/icons/profile_pic_placeholder.svg";

  name = "";

  constructor(public router: Router, public location: Location) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let urlArr = event.url.split("/");
        this.selectedTab = urlArr[urlArr.length - 1];
      }
    });
  }

  ngOnInit() {
    let data = JSON.parse(localStorage.getItem("affiliator_data"));
    this.name = data.name;
  }
  public logOut(e) {
    let opt = e.target.value;
    e.target.value = "name";

    if (opt === "logout") {
      localStorage.removeItem("affiliate_user");
      localStorage.removeItem("affiliator_data");
      sessionStorage.removeItem("last_login");
      window.location.reload();
    }
  }
}

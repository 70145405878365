import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/shared/services/auth.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-verify-email",
  templateUrl: "./verify-email.component.html",
  styleUrls: ["./verify-email.component.scss"],
})
export class VerifyEmailComponent implements OnInit {
  email = "";
  flag = false;
  constructor(public authService: AuthService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      this.email = params.email ? params.email : "";
      this.flag = params.flag ? params.flag === "true" : false;
    });
  }

  ngOnInit() {}
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AppConstants } from "../../app.constants";
import * as moment from "moment";

// apex
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from "ng-apexcharts";
import { AuthService } from "src/app/shared/services/auth.service";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

import { ReferHelpPopupComponent } from "./refer-help-popup/refer-help-popup.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = "start";
  verticalPosition: MatSnackBarVerticalPosition = "bottom";

  // other data
  balanceCredits;
  clicks;
  signups;
  paidSubs;
  totalCredits;

  // refer card
  referraLink: string;

  emailBody1 = `Just wanted to give you a quick heads-up about the online marketing 
  platform I've started using recently. It has some great features that might 
  help you run your business, too. Simply start a 14-day free trial to see if you 
  like it: `;

  emailBody2 = ` . And there's a catch, but a great one: if 
  you decide to go PLUS, we'll both get a $30.00 bonus to redeem for future payments!`;

  emailBody = "";

  // constants for refer buttons to redirect in respective page
  gmailLink = "https://mail.google.com/mail/?view=cm&fs=1&";

  // transactionHistory = [
  //   {
  //     date: "11 jul, 2012",
  //     joined: 20,
  //     amt: 600,
  //     status: "Credited",
  //   },
  //   {
  //     date: "11 jul, 2012",
  //     joined: 20,
  //     amt: 600,
  //     status: "Credited",
  //   },
  //   {
  //     date: "11 jul, 2012",
  //     joined: 20,
  //     amt: 600,
  //     status: "Credited",
  //   },
  //   {
  //     date: "11 jul, 2012",
  //     joined: 20,
  //     amt: 600,
  //     status: "Credited",
  //   },
  // ];

  transactionHistory = [];
  affiliatorAllData;

  // filter select
  selectFilter = "this_month";
  cDate = "";
  signup = "";
  subs = "";

  // apex
  @ViewChild("chart", { static: false }) chart: ChartComponent;
  public signChartOptions;
  public conversionChartOptions;

  signChartData = [];
  conversionChartData = [];

  // getting data
  docId;
  referData;

  // storing sesion
  shouldLastUsedUpdate = false;
  currTime;

  constructor(
    public router: Router,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    public authServices: AuthService
  ) {
    if (!sessionStorage.getItem("last_login")) {
      this.currTime = Math.floor(Date.now() / 1000);
      sessionStorage.setItem("last_login", this.currTime.toString());

      this.shouldLastUsedUpdate = true;
    }
  }

  ngOnInit() {
    this.setGraphVariables();
    if (!JSON.parse(localStorage.getItem("affiliator_data")).on_boarding) {
      this.authServices.ngZone.run(() => this.router.navigate(["/payroll"]));
      return;
    }
    this.getProfile();

    // get graph data
    this.getGraphData();
  }

  public getProfile() {
    this.authServices
      .getProfileData(
        "user_id",
        JSON.parse(localStorage.getItem("affiliate_user")).uid
      )
      .subscribe((res) => {
        if (res[0] !== undefined && res[0].payload !== undefined) {
          this.docId = res[0].payload.doc.id;

          this.referData = res[0].payload.doc.data();

          let accId = null;
          
          if (this.referData.bank.acc_id) accId = this.referData.bank.acc_id;
          this.getbalanceCredits(accId);
          this.getTransactions(accId);
          // filling data

          this.clicks = this.referData.referral.clicks;
          this.signups = this.referData.referral.signups;
          this.paidSubs = this.referData.referral.paid_subs;
          this.totalCredits = this.referData.referral.total_credits;
          this.referraLink = this.referData.referral.link;


          if (this.shouldLastUsedUpdate) {
            let data = {
              updated_at: this.currTime,
            };
            this.authServices
              .updateAffiliatorProfile(data, this.docId)
              .then((res) => {
                this.shouldLastUsedUpdate = false;
              })
              .catch((err) => console.error(err));
          }
        }
      });
  }

  public getbalanceCredits = async (accId) => {
    let data = {
      acc_id: accId,
    };
    let promise = await fetch(`${AppConstants.STRIP.URL}getAftBalance`, {
      method: "POST",
      body: JSON.stringify(data),
    });
    let res = await promise.json();
    if (res.success)
      this.balanceCredits = this.returnAmount(res.data.balance.amount);
  };

  public getTransactions = async (accId) => {
    let promise = await fetch(
      `${AppConstants.STRIP.URL}getPayoutTransactions?user_id=${accId}&limit=100
    `,
      {
        method: "GET",
      }
    );
    let res = await promise.json();
    if(res.success) this.transactionHistory = res.data.transactions;
  };

  public setGraphVariables() {
    if (this.selectFilter === "this_month") {
      this.cDate = moment(new Date()).format("MMM_YY");
      this.signup = "signUpDaily";
      this.subs = "subscriptionDaily";
    } else {
      this.cDate = moment(new Date()).format("YY");
      this.signup = "signUp";
      this.subs = "subscription";
    }
  }

  public handleSelectChange(val) {
    this.selectFilter = val;
    this.cDate =
      this.selectFilter === "this_month"
        ? moment(new Date()).format("MMM_YY")
        : moment(new Date()).format("YY");
    this.signup = this.selectFilter === "this_month" ? "signUpDaily" : "signUp";
    this.subs =
      this.selectFilter === "this_month" ? "subscriptionDaily" : "subscription";

    this.changeGraphArray(this.affiliatorAllData);
    this.renderChart1();
  }

  public validateAndInsert(item, cDate, arrayItems) {
    if (item.date.includes(cDate)) {
      arrayItems.push(item);
    }
  }

  public changeGraphArray(dataContainer) {
    this.signChartData = [];
    this.conversionChartData = [];

    let signupArr = this.signChartData;
    let subsArr = this.conversionChartData;

    dataContainer[this.signup].forEach((data) => {
      this.validateAndInsert(data, this.cDate, signupArr);
    });

    dataContainer[this.subs].forEach((data) => {
      this.validateAndInsert(data, this.cDate, subsArr);
    });
  }

  public getGraphData() {
    this.authServices
      .getAffiliatorRecord(
        JSON.parse(localStorage.getItem("affiliator_data")).ownerId
      )
      .subscribe((res) => {
        if (res && res.payload && res.payload.data()) {
          this.affiliatorAllData = res.payload.data();

          this.changeGraphArray(this.affiliatorAllData);

          this.renderChart1();
        } 
      });
  }

  // chart1
  public renderChart1() {
    // sign in chart
    this.signChartOptions = {
      series: [
        {
          name: "My-series",
          data: this.signChartData.map((data) => data.count),
        },
      ],
      chart: {
        height: 250,
        width: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      // colors: ["#C4C4C4"],
      fill: {
        colors: ["#C4C4C4"],
      },
      xaxis: {
        categories: this.signChartData.map((data) =>
          data.date.replaceAll("_", " ")
        ),
      },
      yaxis: {
        show: true,
        showAlways: true,
      },
    };

    // sign in conversion chart
    this.conversionChartOptions = {
      series: [
        {
          name: "My-series",
          data: this.conversionChartData.map((data) => data.count),
        },
      ],
      chart: {
        height: 250,
        width: "100%",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      // colors: ["#C4C4C4"],
      fill: {
        colors: ["#C4C4C4"],
      },
      xaxis: {
        categories: this.conversionChartData.map((data) =>
          data.date.replaceAll("_", " ")
        ),
      },
      yaxis: {
        show: true,
        showAlways: true,
      },
    };
  }

  onCopyReferalLinkClick(referalLinkInput) {
    referalLinkInput.select();
    document.execCommand("copy");
    referalLinkInput.setSelectionRange(0, 0);
    this.showMessage("Referral URL copied");
  }

  // on mail button click
  composeMail() {
    let to = "someone@example.com";
    let subject = "Join Bindr";
    let mailText: string = `${this.gmailLink}to=${to}&su=${subject}&body=${this.emailBody}`;

    this.referraLink && window.open(mailText, "_blank");
  }

  // on twitter click
  onTwitterClick() {
    let twitLink = `https://twitter.com/intent/tweet?text=${this.emailBody}`;
    this.referraLink && window.open(twitLink, "_blank");
  }

  // Help popup
  openPopup(invoker) {
    if (this.referraLink) {
      let val = invoker === "fb" ? this.emailBody : this.emailBody;

      const help = this.dialog.open(ReferHelpPopupComponent, {
        data: {
          invoker: invoker,
          valToCopy: val,
        },
      });

      help.afterClosed().subscribe((res) => {
        if (res === "fb") {
          this.onFacebookClick();
        } else if (res === "ld") {
          this.onLinkedinClick();
        } else {
        }
      });
    }
  }
  // facebook
  onFacebookClick() {
    let appId = 1257107954709868;
    let url = `http://www.facebook.com/sharer/sharer.php?u=${this.referraLink}`;
    let title = `Bindr AI`;
    let image = `https://bindr.ai/assets/images/logo.png`;

    // this.referraLink && window.open(url, "_blank");

    // let a = `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&link=${image}&redirect_uri=${this.referraLink}`;

    // this.referraLink && window.open(a);

    // testing fb
    // let u = `http://goo.gl/dS52U`;

    this.referraLink &&
      window.open(
        "http://www.facebook.com/sharer.php?u=" +
          encodeURIComponent(this.referraLink) +
          "&t=" +
          encodeURIComponent(this.emailBody),
        "_blank"
      );
    return false;
  }

  // linkedin
  onLinkedinClick() {
    let img =
      "https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg";
    let url = `https://www.linkedin.com/shareArticle?mini=true&url=${this.referraLink}`;
    this.referraLink && window.open(url, "_blank");
  }

  //Date Handler
  getFormatedDate = (date) => {
    return moment(new Date(parseInt(date + "000"))).format("DD MMM YYYY");
  };

  // Remove Underscore
  removeUnderscore=(text) => {
    if (text) {
      let splitArray = text.split("_");
      let formatted = "";
      splitArray.forEach((word, i) => {
        formatted += word + " ";
      });
      return formatted;
    }
    return "";
  };

  // Return Credit or Debit
  returnTransactionType = (amount) => {
    if(amount){
      amount = parseFloat(amount);
      return amount >= 0 ? "Credit" : "Debit";
    }
  }

  returnAmount = (amount) => {
    if(amount){
      amount = parseFloat(amount);
      amount = amount / 100;
      return amount;
    }
  }

  // Toast message
  showMessage = (message) => {
    this.snackbar.open(message, "Okay", {
      duration: 1300,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  };
}

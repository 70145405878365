export const Constants = {
  // Production
  KIOSK_URL_PROD: "https://kiosk.bindr.ai/sign-in",
  STRIP_PROD: {
    KEY: "pk_live_51I3IInB07SwbrUIHyEj1TpwciJlOXlD1DfguzxWcnQzom9xbM7w0mL3Mtqb8H7z3DbebmnPIVdH2H5EZjL12T4zj00n8vVAVTq",
    URL: "https://us-central1-healthapp-caac0.cloudfunctions.net/",
    PRICE_IDS: {
      BASIC: "price_1I9OI1B07SwbrUIHI8067lKf",
      ESSENTIAL_MONTHLY: "price_1I9OIJB07SwbrUIHc0O4i1cw",
      ESSENTIAL_YEARLY: "price_1I9OINB07SwbrUIHz4gPtBWM",
      ADVANCED_MONTHLY: "price_1I9OIDB07SwbrUIHpHePoelj",
      ADVANCED_YEARLY: "price_1I9OI7B07SwbrUIHvl8CM5Ry",
    },
  },
  MAIL_PROD: {
    URL: "https://us-central1-healthapp-caac0.cloudfunctions.net/sendInvite",
  },
  productionConfig: {
    apiKey: "AIzaSyC9bg5DXC3PGoco_9nRHgDl0bvGA1DAdxo",
    authDomain: "healthapp-caac0.firebaseapp.com",
    databaseURL: "https://healthapp-caac0.firebaseio.com",
    projectId: "healthapp-caac0",
    storageBucket: "healthapp-caac0.appspot.com",
    messagingSenderId: "934480847202",
    appId: "1:934480847202:web:34730fd8b4876cb567cc3b",
    measurementId: "G-GMYG2MTHL0",
  },

  // Staging
  KIOSK_URL_STAGING: "https://healthkioskdev.firebaseapp.com/sign-in",
  STRIP_STAGING: {
    KEY: "pk_test_51I3IInB07SwbrUIHNX0RWzDwGrOkfERtMpzK06NesXTHqT3iD1rbHbc8RGqX02sGRmvBOiNMnml6qVpbysgmmMwn00vrS5hFTj",
    URL: "https://us-central1-healthappdev-81197.cloudfunctions.net/",
    PRICE_IDS: {
      BASIC: "price_1I6FJ3B07SwbrUIHCYIPe8rj",
      ESSENTIAL_MONTHLY: "price_1I5AGBB07SwbrUIHn2g1W19E",
      ESSENTIAL_YEARLY: "price_1I5AFLB07SwbrUIHrdWrjb45",
      ADVANCED_MONTHLY: "price_1I6FHRB07SwbrUIHktawmbMa",
      ADVANCED_YEARLY: "price_1I6FHrB07SwbrUIHzYnBjeYl",
    },
  },
  MAIL_STAGE: {
    URL: "https://us-central1-healthappdev-81197.cloudfunctions.net/sendInvite",
  },
  DEEP_LINKING: {
    URL: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAJG0x3nv82Las25LQm48HYB4umtmy7Q28",
  },
  stagingConfig: {
    apiKey: "AIzaSyAJG0x3nv82Las25LQm48HYB4umtmy7Q28",
    authDomain: "healthappdev-81197.firebaseapp.com",
    databaseURL: "https://healthappdev-81197.firebaseio.com",
    projectId: "healthappdev-81197",
    storageBucket: "healthappdev-81197.appspot.com",
    messagingSenderId: "107816447354",
    appId: "1:107816447354:web:7aa57673657fd711a57ac3",
    measurementId: "G-3P11EE7X44",
  },
};

export const AppConstants = {

  DEEP_LINKING: Constants.DEEP_LINKING,

  // Production
  STRIP: Constants.STRIP_PROD,
  Config: Constants.productionConfig,

  // Staging
  // STRIP: Constants.STRIP_STAGING,
  // Config: Constants.stagingConfig,
};
